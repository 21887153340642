import React, { useEffect, useState } from "react"
import FacetFilter from "./FacetFilter"
import classnames from "classnames"

import { FacetDistribution, NamedEntity } from "../utils/model"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { defaultSearchFilters, useSearchContext } from "./SearchContextProvider"
import { FacetValueSummary } from "./FacetValueSummary"
import { toUpper } from "lodash"

type FacetFiltersProps = {
  show: boolean
  toggle: () => void
}

export const formatName = name => {
  const withoutPrefix =
    name.indexOf("#") > -1 ? name.substring(name.lastIndexOf("#") + 1) : name
  return withoutPrefix.indexOf("|") > -1
    ? withoutPrefix.substring(0, withoutPrefix.indexOf("|") - 1)
    : withoutPrefix
}

export const extractOrder = name => {
  if (name) {
    return name.substring(0, name.lastIndexOf("#"))
  }
}

const sortCategories = (a: NamedEntity, b: NamedEntity) => {
  let ordersA = extractOrder(a.name).split(".")
  let ordersB = extractOrder(b.name).split(".")
  ordersA.pop()
  ordersB.pop()

  const nameA = parseInt(ordersA.pop())
  const nameB = parseInt(ordersB.pop())

  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  return 0
}

const FacetFilters = ({ show, toggle }: FacetFiltersProps) => {
  const [showMenu, setShowMenu] = useState(show)

  useEffect(() => {
    setShowMenu(show)
  }, [show])

  const {
    facetsDistributions,
    searchFilters: { facets },
    searchResult,
    actions,
  } = useSearchContext()

  return (
    <div
      className={classnames(
        "search-nav bg-primary text-white fixed left-0 top-0 w-screen h-screen z-5000 overflow-auto pb-16",
        { hidden: !showMenu },
        "md:block md:relative md:h-auto md:w-1/3 lg:w-1/4 md:z-0 md:pb-0 md:flex-grow"
      )}
    >
      <div className="flex flex-wrap flex-grow p-4 pb-0">
        <h3 className="flex-1 text-lg uppercase font-bold">Filtres</h3>
        <div className="md:hidden">
          <FontAwesomeIcon
            fixedWidth
            onClick={toggle}
            size="lg"
            icon={faTimes}
          />
        </div>
      </div>
      <div className="p-4 flex flex-wrap">
        {Object.entries(facets).map(([facetKey, facetValues]) =>
          facetValues.map(facetValue => (
            <FacetValueSummary
              key={facetValue}
              facetName={facetKey}
              facetValue={facetValue}
            />
          ))
        )}
      </div>

      {facetsDistributions.length > 0 && (
        <ul>
          <FacetFilter
            title="Secteur"
            facet={facetsDistributions.find(({ name }) => name === "secteur")}
            expand={true}
            show={!Boolean(facets.secteur)}
            showValue={() => true}
            type="pill"
            sortFn={sortCategories}
          />
          <FacetFilter
            title={
              facets.secteur ? formatName(facets.secteur?.[0]) : "sous-secteur"
            }
            facet={facetsDistributions.find(f => f.name === "sous-secteur")}
            expand={true}
            showValue={(v, c) =>
              c > 0 && v.startsWith(extractOrder(facets.secteur?.[0]))
            }
            show={Boolean(facets.secteur) && !Boolean(facets["sous-secteur"])}
            type="pill"
            sortFn={sortCategories}
          />

          <FacetFilter
            title={
              facets["sous-secteur"]
                ? formatName(facets["sous-secteur"]?.[0])
                : "famille"
            }
            facet={facetsDistributions.find(f => f.name === "famille")}
            expand={true}
            showValue={(v, c) =>
              c > 0 && v.startsWith(extractOrder(facets["sous-secteur"]?.[0]))
            }
            show={
              Boolean(facets["sous-secteur"] && facets["secteur"]) &&
              !Boolean(facets["famille"])
            }
            type="pill"
          />
          <FacetFilter
            title={
              facets["famille"]
                ? formatName(facets["famille"]?.[0])
                : "sous-famille"
            }
            facet={facetsDistributions.find(f => f.name === "sous-famille")}
            expand={true}
            showValue={(v, c) =>
              c > 0 && v.startsWith(extractOrder(facets["famille"]?.[0]))
            }
            show={
              Boolean(
                facets["sous-secteur"] && facets["secteur"] && facets["famille"]
              ) && !Boolean(facets["sous-famille"])
            }
            type="pill"
          />

          <FacetFilter
            title="Offres d'emploi"
            facet={facetsDistributions.find(f => f.name === "jobBoard")}
            expand={true}
            show={true}
            showValue={(v, c) => true}
            type="checkbox"
          />

          <FacetFilter
            title="Pays"
            facet={facetsDistributions.find(f => f.name === "countries")}
            expand={false}
            show={true}
            type="select"
            showValue={() => true}
            options={facetsDistributions
              .find(({ name }) => name === "countries")
              ?.distribution.map(({ name }) => ({
                //we do not use id as this is meilisearch internal id for facet distribution.
                id: name,
                name: toUpper(name),
              }))}
            displaySummary
          />

          <FacetFilter
            title="Régions"
            facet={facetsDistributions.find(f => f.name === "regions")}
            expand={false}
            show={true}
            type="select"
            showValue={() => true}
            options={facetsDistributions
              .find(({ name }) => name === "regions")
              ?.distribution.map(({ name }) => ({
                //we do not use id as this is meilisearch internal id for facet distribution.
                id: name,
                name: toUpper(name),
              }))}
            displaySummary
          />

          <FacetFilter
            title="Départements"
            facet={facetsDistributions.find(f => f.name === "departments")}
            expand={false}
            show={true}
            type={"select"}
            showValue={() => true}
            options={facetsDistributions
              .find(({ name }) => name === "departments")
              ?.distribution.map(({ name }) => ({
                //we do not use id as this is meilisearch internal id for facet distribution.
                id: name,
                name: toUpper(name),
              }))}
            displaySummary
          />
          <FacetFilter
            title="Villes"
            facet={facetsDistributions.find(f => f.name === "fullLocations")}
            expand={false}
            show={true}
            type={"select"}
            showValue={() => true}
            options={facetsDistributions
              .find(({ name }) => name === "fullLocations")
              ?.distribution.map(({ name }) => ({
                //we do not use id as this is meilisearch internal id for facet distribution.
                id: name,
                name: toUpper(name),
              }))}
            displaySummary
          />
          <FacetFilter
            title="Types de structure"
            facet={facetsDistributions.find(f => f.name === "type")}
            expand={false}
            show={true}
            showValue={(v, c) => true}
            type="checkbox"
          />
          <FacetFilter
            title="Effectifs"
            facet={facetsDistributions.find(f => f.name === "workforce")}
            expand={false}
            show={true}
            showValue={(v, c) => true}
            type="checkbox"
          />
          <FacetFilter
            title="Leviers"
            facet={facetsDistributions.find(f => f.name === "lever")}
            expand={true}
            show={true}
            showValue={(v, c) => true}
            type="checkbox"
          />
        </ul>
      )}

      <div className="flex justify-around px-4 py-2 fixed left-0 bottom-0 w-screen bg-white lg:hidden">
        <button
          className="text-primary text-sm font-bold rounded border-2 border-secondary px-4 py-2 mr-4"
          onClick={() => actions.replaceFilters(defaultSearchFilters)}
        >
          Effacer
        </button>
        <button
          className="text-primary text-sm bg-secondary font-bold rounded px-4 py-2"
          onClick={toggle}
        >
          Afficher les résultats ({searchResult.count})
        </button>
      </div>
    </div>
  )
}

export default FacetFilters
