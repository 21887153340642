import { useEffect, useMemo } from "react"
import Fuse from "fuse.js"

export function useFuse<T>(fuseOptions: Fuse.IFuseOptions<T>, entities: T[]) {
  const fuse = useMemo(() => new Fuse<T>(entities, fuseOptions), [
    entities,
    fuseOptions,
  ])
  useEffect(() => void fuse.setCollection(entities), [entities, fuse])
  return fuse
}

export function useFuzzyFilteredEntities<T>(
  fuseOptions: Fuse.IFuseOptions<T>,
  entities: T[],
  filter: string
) {
  const mergedFuseOptions = useMemo(
    () => ({ ...fuseOptions, includeScore: true }),
    [fuseOptions]
  )
  const fuse = useFuse(mergedFuseOptions, entities)
  const fuzzyfilteredEntities = useMemo(() => {
    if (filter.length < 2) {
      return entities
    }
    const searchResults = fuse.search(filter) as Fuse.FuseResult<T>[]
    return searchResults.map(({ item }) => item)
  }, [entities, filter, fuse])
  return fuzzyfilteredEntities
}

export const nameEntitiesFuseOptions = { keys: ["name"] }
