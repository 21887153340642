import React, { useMemo } from "react"
import { useSearchContext } from "./SearchContextProvider"
import classnames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { usePaginatedEntitieData } from "../utils/paginationUtils"

const MAX_NUMBER_OF_DISPLAYED_PAGES = 2

const Pagination = props => {
  const {
    searchFilters,
    searchResult,
    actions: { setPage },
  } = useSearchContext()
  const { hasMoreEntities, numberOfPages } = usePaginatedEntitieData(
    searchResult.count,
    searchFilters.page - 1,
    searchFilters.perPage
  )
  const pages = useMemo(() => {
    if (numberOfPages === 0) {
      return []
    }
    const minPageIndex = Math.max(
      1,
      searchFilters.page - MAX_NUMBER_OF_DISPLAYED_PAGES
    )
    const maxPageOffset = Math.min(
      MAX_NUMBER_OF_DISPLAYED_PAGES,
      numberOfPages - searchFilters.page
    )
    const numberOfDisplayedPages =
      1 + maxPageOffset + searchFilters.page - minPageIndex
    return [...Array(numberOfDisplayedPages).keys()].map(
      pageNumber => pageNumber + minPageIndex
    )
  }, [searchFilters.page, numberOfPages])

  return (
    <div className="flex justify-center">
      {searchFilters.page !== 1 && (
        <button
          className="hover:bg-gray-400 text-primary font-bold py-2 px-4 rounded-l"
          onClick={() =>
            searchFilters.page > 1 ? setPage(searchFilters.page - 1) : null
          }
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      )}
      {pages.map(page => (
        <button
          className={classnames(
            "hover:bg-gray-400 font-bold py-2 px-4 rounded-r",
            searchFilters.page === page
              ? "text-secondary underline"
              : "text-primary"
          )}
          onClick={() => setPage(page)}
        >
          {page}
        </button>
      ))}

      {hasMoreEntities && (
        <button
          className="hover:bg-gray-400 text-primary font-bold py-2 px-4 rounded-r"
          onClick={() =>
            hasMoreEntities ? setPage(searchFilters.page + 1) : null
          }
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      )}
    </div>
  )
}

export default Pagination
