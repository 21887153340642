import React, { useCallback } from "react"
import { useSearchContext } from "./SearchContextProvider"
import { formatName } from "./FacetFilters"
import { capitalize } from "lodash"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type FacetValueSummaryProps = {
  facetName: string
  facetValue: string
}

const FacetValueSummary = ({
  facetValue,
  facetName,
}: FacetValueSummaryProps) => {
  const {
    actions: { removeFilter },
  } = useSearchContext()
  const handleRemoveFacet = useCallback(() => {
    removeFilter(facetName, facetValue)
  }, [facetName, facetValue, removeFilter])
  return (
    <div className="flex items-center bg-white text-primary rounded p-1 mr-2 mb-2 text-xs">
      <span>{capitalize(formatName(facetValue))} </span>
      <FontAwesomeIcon
        onClick={handleRemoveFacet}
        className="cursor-pointer mx-1"
        size="lg"
        icon={faTimes}
      />
    </div>
  )
}

export { FacetValueSummary }
