import OrganizationsMap, {
  FULL_COUNTRY_ORGANIZATION_CITY,
} from "./OrganizationsMap/OrganizationsMap"
import React, { useCallback, useMemo, useRef, useState } from "react"
import {
  faInfo,
  faListUl,
  faMap,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons"

import FacetFilters from "./FacetFilters"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import OrgLine from "../components/OrgLine"
import Pagination from "./Pagination"
import { formatQueryParameters } from "../utils/HistoryUtils"
import iconAdd from "../images/mdi_add_circle_outline.svg"
import { markFormClick } from "../utils/forms"
import { useSearchContext } from "./SearchContextProvider"

const scrollToRef = ref => {
  window.scrollTo(0, ref.current.offsetTop + 10)
}

const emptyFullLocations = []

const Search = () => {
  const markclick = markFormClick

  const [showFilter, setShowFilter] = useState(false)
  const handleChangeShowFilter = () => {
    setShowFilter(!showFilter)
  }
  const mapRef = useRef(null)

  let {
    searchResult,
    searchFilters: {
      viewMode,
      query,
      facets: { fullLocations = emptyFullLocations, ...otherFacets },
    },
    actions: { setViewMode },
  } = useSearchContext()

  const shouldDisplayFullCountryMessage = useMemo(() => {
    const isFullCountryLocationSelected = fullLocations.includes(
      FULL_COUNTRY_ORGANIZATION_CITY
    )
    if (fullLocations.length === 0 || isFullCountryLocationSelected) {
      return false
    } else {
      return true
    }
  }, [fullLocations])

  const showMap = useMemo(() => viewMode === "map", [viewMode])

  const handleViewModeListClicked = useCallback(() => {
    setViewMode("list")
  }, [setViewMode])

  const linkToFullCountryOrganizations = `/search/?${formatQueryParameters({
    fullLocations: FULL_COUNTRY_ORGANIZATION_CITY,
    query,
    ...otherFacets,
  })}`

  const organizationsComponent = useMemo(() => {
    switch (viewMode) {
      case "list":
        return (
          <div>
            <div className="items-stretch w-full mb-8">
              {/* <div className="grid items-stretch gap-4 grid-cols-1 lg:grid-cols-2 grid-auto-rows-organizations w-full px-4 mb-8"> */}
              {searchResult.isLoading ? (
                <div>Loading ...</div>
              ) : (
                searchResult.organizations.map(org => (
                  <OrgLine key={org.id} organization={org} />
                ))
              )}
            </div>

            {shouldDisplayFullCountryMessage && (
              <div className="mx-2 lg:mx-0 whitespace-pre-wrap">
                <FontAwesomeIcon className="mr-2" size="lg" icon={faInfo} />
                Découvrir{" "}
                <Link
                  to={linkToFullCountryOrganizations}
                  className="custom-underline"
                >
                  ici
                </Link>{" "}
                la liste des acteurs nationaux présents sur l’ensemble du
                territoire.
              </div>
            )}
            <Pagination />
          </div>
        )
      case "map":
        return (
          <div className="bg-white w-full relative" style={{ height: "500px" }}>
            <OrganizationsMap
              mapOrganizations={searchResult.mapOrganizations}
            />
          </div>
        )
    }
  })

  return (
    <div className="flex font-sans">
      <FacetFilters show={showFilter} toggle={handleChangeShowFilter} />
      <div className="search-content md:w-2/3 lg:w-3/4 flex flex-col ">
        <div className="px-4 pb-4 lg:px-16 border-b">
          <h1 className="text-primary font-bold text-left text-3xl xl:text-5xl lg:pt-6 lg:pb-4">
            Les organisations
          </h1>
          <p className="font-serif text-sm">
            Découvrez l’ensemble des entreprises et organisations que nous avons
            identifiées comme contributives à la transition carbone.
            <br />
            Vous pouvez les filtrer par secteur et sous-secteur d’activité sur
            de nombreux critères.
          </p>
        </div>
        <div ref={mapRef} className="lg:px-16 h-full ">
          <div className="w-full flex text-primary font-bold text-sm my-4 flex-col md:flex-row items-end md:items-center justify-between">
            <a
              href="https://airtable.com/shrNptfb5u8xVAmxU"
              target="_blank"
              onClick={() => markclick("Ajouter une organisation")}
              className="font-sans font-bold flex items-center p-2 md:py-2"
            >
              <span className="mr-2 text-center">
                <img className="mx-auto" src={iconAdd} />
              </span>
              <span>Ajouter une organisation</span>
            </a>
            <div className="flex">
              <span className="mr-2 mt-3">
                {searchResult.count}{" "}
                {searchResult.count > 1 ? "organisations" : "organisation"}
              </span>

              <div className="bg-white rounded-full flex items-center justify-center p-1">
                <button
                  className={
                    showMap
                      ? "bg-white rounded-full flex items-center justify-center  p-2"
                      : "bg-primary text-white rounded-full flex items-center justify-center  p-2"
                  }
                  onClick={handleViewModeListClicked}
                >
                  <FontAwesomeIcon size="lg" icon={faListUl} className="mr-2" />
                  Liste
                </button>
                <button
                  className={
                    showMap
                      ? "bg-primary text-white rounded-full flex items-center justify-center  p-2"
                      : "bg-white rounded-full flex items-center justify-center  p-2"
                  }
                  onClick={() => {
                    setViewMode("map")
                    scrollToRef(mapRef)
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faMap} className="mr-2 " />
                  Carte
                </button>
              </div>
            </div>
          </div>
          {organizationsComponent}
        </div>
      </div>
      <div className="fixed right-0 bottom-0 lg:hidden mb-8 mr-8 z-1000">
        <button
          className="rounded-full h-16 w-16 flex items-center justify-center bg-secondary text-primary"
          onClick={handleChangeShowFilter}
        >
          <FontAwesomeIcon size="lg" icon={faSlidersH} />
        </button>
      </div>
    </div>
  )
}

export default Search
