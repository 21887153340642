import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Search from "../components/Search"
import { navigate } from "gatsby"
import {
  SearchContextProvider,
  defaultSearchFilters,
} from "../components/SearchContextProvider"
import { sortBy } from "../utils/commonUtils"
import { useCategoryTree } from "../utils/custom-hooks"
import queryString from "query-string"

function seoData(params) {
  let hasQuery = false
  let seoTitle = "Les employeurs : "
  let gtmValue,
    gtmKey = "plo-"

  for (const [key, value] of params) {
    gtmKey += `${key}-`
    if (value.indexOf("#") !== -1) {
      seoTitle += `${key} ${value.split("#")[1]}, `
      gtmValue += `${value.split("#")[1]} - `
    } else {
      if (key === "query") {
        hasQuery = true
        seoTitle += `${value} - `
      } else {
        seoTitle += `${key} ${value} - `
      }
      gtmValue += `${value} - `
    }
    if (key === "query") {
      gtmValue = `search: ${value}}`
      gtmKey = "search"
    }
  }
  if ([...params].length > 0) {
    seoTitle = seoTitle.substring(0, seoTitle.length - 2)
  }
  if (hasQuery) {
    seoTitle = seoTitle + " - recherche SHIFT YOUR JOB"
  }
  return { seoTitle, gtmValue, gtmKey }
}

function SearchPage(props) {
  useEffect(() => {
    document.body.className = "page-search"
  }, [])

  let categories = useCategoryTree()

  const url = new URL(props.location.href ?? "https://shiftyourjob.org/search/")
  // SEO FILTERS
  let seoTitle,
    googlebot,
    gtmValue,
    gtmKey = ""
  let seoDesc =
    "Explorez la liste des entreprises et organisations qui oeuvrent pour la transition carbone et un monde plus durable"

  const page = url.searchParams.get("page")
  if (page && page.toString() === "1") {
    url.searchParams.delete("page")
    navigate(window.location.pathname + "?" + url.searchParams.toString(), {
      replace: true,
    })
  }

  if (url.searchParams.toString().length > 0) {
    googlebot = "noindex, nofollow"
    ;({ seoTitle, gtmValue, gtmKey } = seoData(url.searchParams))
  } else {
    googlebot = "index, follow"
    seoTitle =
      "Les employeurs pour la transition carbone & le développement durable "
    gtmValue = "plo"
    gtmKey = "plo"
  }

  let searchResult = null
  let facets = null
  if (props.pageContext.organizations) {
    let organizations = props.pageContext.organizations.sort(sortBy("name"))

    let result = []
    const map = new Map()
    for (const item of organizations) {
      if (!map.has(item.id)) {
        map.set(item.id, true) // set any value to Map
        result.push(item)
      }
    }

    const category = categories.find(props.pageContext.categoryId)

    facets = category.filters().filters

    searchResult = {
      isLoading: false,
      count: result.length,
      organizations: result.slice(0, defaultSearchFilters.perPage),
      facets: facets,
    }
    ;({ seoTitle, gtmValue, gtmKey } = seoData(
      new URLSearchParams(queryString.stringify(facets))
    ))
    seoDesc = category.SEOSearchDesc
  }

  return (
    <SearchContextProvider searchResult={searchResult} facets={facets}>
      <Layout contentClassName="bg-gray-100 ">
        <SEO
          meta={[{ name: "googlebot", content: `${googlebot}` }]}
          title={seoTitle}
          description={seoDesc}
          gtmPageType={`${gtmKey}`}
          gtmPageName={`${gtmValue}`}
        />
        <Search />
      </Layout>
    </SearchContextProvider>
  )
}

export default SearchPage
