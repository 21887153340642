import React from "react"

export default function Pill({ name, selected }) {
  return (
    <div
      style={{ maxWidth: "90%" }}
      className={`no-underline truncate leading-snug text-white font-bold rounded border border-gray-600 px-2 py-1 transition duration-300 ease hover:border-white inline-flex items-center ${
        selected ? "bg-secondary" : ""
      }`}
    >
      <span className="text-sm truncate">{name}</span>
    </div>
  )
}

{
  /* <div className="no-underline inline-block leading-snug text-white font-bold rounded border border-gray-600 px-4 py-2 mt-4 ">
    <span className="text-sm capitalize-first-letter">{cat.name}</span>
    <span className="bg-gray-600 h-4 w-px top-0 left-0 mx-2"></span>
    <span className="text-sm">{cat.totalOrgCount}</span>
</div> */
}
